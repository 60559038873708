import React, { lazy, Suspense } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import { Loading } from "./GlobalComponents";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { GoogleOAuthProvider } from "@react-oauth/google";

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  // Add other theme customization as necessary
});

const App = lazy(() => import("./App"));

const root = ReactDOM.createRoot(document.getElementById("root"));

const CLIENT_ID = process.env.REACT_APP_GOOGLE_ANALYTICS_CLIENT_ID;
root.render(
  <Router>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Suspense fallback={<Loading />}>
        <App />
      </Suspense>
    </ThemeProvider>
  </Router>,
);
