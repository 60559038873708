import {
  AiFillDollarCircle,
  AiFillHome,
  AiOutlineDollar,
} from "react-icons/ai";
import {
  IoBarChartSharp,
  IoCalendarOutline,
  IoEyeOffOutline,
  IoEyeOutline,
  IoImageOutline,
  IoPersonCircle,
  IoPersonSharp,
  IoQrCode,
  IoReceiptOutline,
  IoSearch,
  IoStorefrontSharp,
  IoTicketSharp,
  IoTimeOutline,
  IoTimerOutline,
  IoTrashBinOutline,
} from "react-icons/io5";
import {
  BsPeopleFill,
  BsFillClockFill,
  BsFillCalendarFill,
  BsFillBagFill,
  BsBox2,
  BsFillTelephoneFill,
  BsStripe,
} from "react-icons/bs";
import { GrMoney, GrSchedules } from "react-icons/gr";
import { DiGoogleAnalytics } from "react-icons/di";
import {
  MdAnnouncement,
  MdConfirmationNumber,
  MdDashboard,
  MdDownload,
  MdEmail,
  MdGroups,
  MdLocationOn,
  MdOutlineEventRepeat,
  MdOutlineKeyboardArrowDown,
  MdOutlineKeyboardArrowUp,
  MdOutlineMessage,
  MdOutlinePhoneIphone,
  MdOutlineRemoveRedEye,
  MdOutlineSort,
  MdPeopleAlt,
} from "react-icons/md";
import {
  FaBox,
  FaFacebook,
  FaFacebookF,
  FaInstagram,
  FaLinkedinIn,
  FaQuestionCircle,
  FaRegAddressCard,
  FaRegImage,
  FaRegMoneyBillAlt,
  FaRegQuestionCircle,
  FaSignOutAlt,
  FaStamp,
  FaStoreAlt,
} from "react-icons/fa";
import {
  TbHomeMove,
  TbMoneybag,
  TbPigMoney,
  TbRubberStamp,
} from "react-icons/tb";
import {
  IoIosAddCircle,
  IoIosArrowBack,
  IoIosArrowDown,
  IoIosArrowForward,
  IoIosArrowUp,
  IoIosListBox,
  IoMdSettings,
  IoIosInformationCircleOutline,
} from "react-icons/io";
import { FiPlusCircle } from "react-icons/fi";
import { RxDotFilled } from "react-icons/rx";

export const aiFillHome = <AiFillHome />;
export const ioStorefrontSharp = <IoStorefrontSharp />;
export const bsPeopleFill = <BsPeopleFill />;
export const bsFillClockFill = <BsFillClockFill />;
export const bsFillCalendarFill = <BsFillCalendarFill />;
export const grMoney = <GrMoney />;
export const diGoogleAnalytics = <DiGoogleAnalytics />;
export const mdOutlineKeyboardArrowDown = <MdOutlineKeyboardArrowDown />;
export const mdOutlineKeyboardArrowUp = <MdOutlineKeyboardArrowUp />;
export const IoInfoOutline = <IoIosInformationCircleOutline />;

// ---------- New ----------

// user
export const avatar = <IoPersonCircle />;
export const customerHome = <TbHomeMove />;

// Business Sidebar
export const dashboard = <MdDashboard />;
export const store = <FaStoreAlt />;
export const people = <MdPeopleAlt />;
export const bag = <BsFillBagFill />;
export const membership = <FaRegAddressCard />;
export const calendar = <IoCalendarOutline />;
export const order = <FaBox />;
export const analytics = <IoBarChartSharp />;
export const finance = <TbPigMoney />;
export const person = <IoPersonSharp />;
export const message = <MdOutlineMessage />;
export const create = <IoIosAddCircle />;
export const management = <IoIosListBox />;
export const redeem = <FaStamp />;
export const announcement = <MdAnnouncement />;
export const setting = <IoMdSettings />;
export const signout = <FaSignOutAlt />;
export const helpFilled = <FaQuestionCircle />;
export const filledOutline = <FaRegQuestionCircle />;
export const qrcode = <IoQrCode />;

// Nav bar

// social Media
export const LinkedIn = <FaLinkedinIn />;
export const Facebook = <FaFacebookF />;
export const Instagram = <FaInstagram />;
export const iPhone = <MdOutlinePhoneIphone />;
export const Stripe = <BsStripe />;

// Action
export const download = <MdDownload />;
export const upload = <IoImageOutline />;
export const preview = <MdOutlineRemoveRedEye />;
export const bin = <IoTrashBinOutline />;
export const receipt = <IoReceiptOutline />;
export const search = <IoSearch />;
export const filter = <MdOutlineSort />;
export const stamp = <TbRubberStamp />;
export const call = <BsFillTelephoneFill />;
export const email = <MdEmail />;
export const map = <MdLocationOn />;
export const visible = <IoEyeOutline />;
export const invisible = <IoEyeOffOutline />;
export const photo = <FaRegImage />;

// business
export const subTypes = <MdOutlineEventRepeat />;
export const subscriber = <MdGroups />;
export const revenue = <TbMoneybag />;
export const coupon = <IoTicketSharp />;
export const time = <IoTimeOutline />;
export const Dot = <RxDotFilled />;

// arrows
export const upArrow = <IoIosArrowUp />;
export const downArrow = <IoIosArrowDown />;
export const rightArrow = <IoIosArrowForward />;
export const leftArrow = <IoIosArrowBack />;

// item counts
export const schedule = <GrSchedules />; // service
export const box = <BsBox2 />; // product
// membership is people icon above
export const interval = <IoTimerOutline />;
export const priceFilled = <AiFillDollarCircle />;
export const priceOutline = <AiOutlineDollar />;
export const use = <MdConfirmationNumber />;
export const unitPrice = <FaRegMoneyBillAlt />;
