import React, { useEffect, useRef, useState } from "react";
import NavIcon from "../../Component/Atom/NavIcon";
import {
  avatar,
  customerHome,
  person,
  PlusCircle,
} from "../../icons/business/icons";
import { FiPlusCircle } from "react-icons/fi";
import { CSSTransition } from "react-transition-group";
import { useNavigate } from "react-router-dom";
import { Link, useLocation } from "react-router-dom";
import { MdOutlineMessage } from "react-icons/md";
import { getBusinessToken } from "../../Functions/Auth/getToken";
import axios from "axios";
import { businessSignOut } from "../../Functions/Auth/signout";
import { accessStripe } from "../../Business_Module/BusinessStateManagement/businessState";
import useWindowSize from "../../Functions/Hooks/useWindowSize";

const NavLink = () => {
  const Url = require("../../backendURL");
  const [open, setOpen] = useState(false);
  const [activeTarget, setActiveTarget] = useState("");
  const [dropdownPosition, setDropdownPosition] = useState({ x: 0 });
  const [counter, setCounter] = useState(0);
  const location = useLocation();
  const navigate = useNavigate();

  const dropdownRef = useRef(null);

  // For live store
  const businessId = localStorage.getItem("businessId");
  const isMobile = useWindowSize()

  useEffect(() => {
    if (dropdownRef.current) {
      const rect = dropdownRef.current.getBoundingClientRect();
      setDropdownPosition({ x: rect.left });
    }
  }, [open]);

  const [notifs, setNotifs] = useState([]);

  // Auto Refresh unseen messages
  const fetchUnseenMessages = async () => {
    setTimeout(() => {
      const isLoggedIn = localStorage.getItem("isBusinessLoggedIn");
      if (isLoggedIn) {
        setCounter(counter + 1);
      }
    }, 10000);

    if (!location.pathname.includes("chatbox")) {
      const userId = localStorage.getItem("businessId");
      const token = await getBusinessToken();
      const notifData = await axios.get(
        `${Url}chatbox/get-notifs/seller/${userId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        },
      );
      setNotifs(notifData.data);
    }
  };

  useEffect(() => {
    const isLoggedIn = localStorage.getItem("isBusinessLoggedIn");
    if (isLoggedIn) {
      fetchUnseenMessages();
    }
  }, [counter]);

  // End of notification code
  if(location.pathname === "/businessportal/registrationPage"){
    return(
      <Link 
        type="button" 
        className="l-nav__businessLink" 
        to={`/customer/home`}
        style={isMobile?{order:2,color:"#2A393E"}:{color:"#2A393E"}}
        >
      Go Back to Home
    </Link>
    )
  }
  return (
    <>
    {
      isMobile ?  (
        <Link
              to="/businessportal/create/membership"
              type="button"
              className="l-nav__businessLink l-nav__businessLink__createSubscription"
              style={{order:2}}
            >
              <div>
                Create Subscription
                <FiPlusCircle />
              </div>
            </Link>
      ) : ("")
    }
    <aside className="l-navLink">
      {/* Stephen: Added link to navbar, couldn't go into NavLinks since that only renders on buyer side */}

      {localStorage.getItem("isBusinessLoggedIn") ? (
        <>
          <Link type="button" className="l-nav__businessLink" to={`/businessportal/sellerPlusHelp`}>
            <div>SellerPlus+</div>
          </Link>
          <Link
            type="button"
            onClick={accessStripe}
            className="l-nav__businessLink l-nav__businessLink__stripe"
          >
            <div>Stripe</div>
          </Link>

          <Link
            to={`/customer/customerStoreFront/${businessId}`}
            type="button"
            className="l-nav__businessLink"
          >
            <div>Live Store</div>
          </Link>

          {location.pathname != `/businessportal/create/membership` ? isMobile ? (""):(
            <Link
              to="/businessportal/create/membership"
              type="button"
              className="l-nav__businessLink l-nav__businessLink__createSubscription"
            >
              <div>
                Create Subscription
                <FiPlusCircle />
              </div>
            </Link>
          ) : (
            <>
            </>
          )}

          {(location.pathname != `/businessportal/dashboard`) &
          (location.pathname != `/businessportal`) ? (
            <Link
              to="/businessportal/dashboard"
              type="button"
              className="l-nav__businessLink"
            >
              <div>Dashboard</div>
            </Link>
          ) : (
            <></>
          )}

          <Link
            className="l-nav__businessLink l-nav__businessLink__signOut"
            onClick={businessSignOut}
            type="button"
          >
            <div>Sign Out</div>
          </Link>
        </>
      ) : (
        ""
      )}
    </aside>
    </>
  );
};

export default NavLink;
